import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import { documentsConfig } from '@src/pages/OnboardingChecklistV2/common/checkpointsConfig'
import { DocumentsIntro } from './DocumentsIntro'
import { OnboardingChecklistContent } from '@src/pages/OnboardingChecklistV2/components/OnboardingChecklistContent'

const routes = [
  {
    title: 'Intro',
    path: ROUTES.ONBOARDING_CHECKLIST_V2.DOCUMENTS.INTRO,
    url: ROUTES.ONBOARDING_CHECKLIST_V2.DOCUMENTS.INTRO,
    canView: [PermissionTypes.ViewDocumentsPreferences],
    component: DocumentsIntro,
  },
]

export const Documents = () => {
  return <OnboardingChecklistContent config={documentsConfig} routes={routes} />
}
