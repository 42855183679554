import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react'
import { AxiosError } from 'axios'
import omit from 'lodash/omit'
import flatten from 'flat'
import * as Sentry from '@sentry/react'

import { arrayErrorsToFormError } from '@src/utils/form'
import { FormError, useLapeContext } from './LapeForm'
import { pushError, pushNotification } from '@src/store/notifications/actions'
import isEmpty from 'lodash/isEmpty'
import { NotificationTypes } from '@src/store/notifications/types'
import { ERROR_DEFAULT_DURATION } from '@src/constants/notifications'
import { Box } from '@revolut/ui-kit'

const errorKeysToIgnore = ['non_field_errors']

const FormValidatorContext = createContext<{
  validated: boolean
  validate: (callback: () => Promise<any>) => () => void
  forceErrors: (errors: FormError<{}>) => void
  onPushError: (callback: (errors: FormError<{}>) => void) => void
} | null>(null)

// Use this when consumer may not be wrapped in FormValidatorProvider, otherwise use useSafeFormValidator
export const useFormValidator = () => {
  return useContext(FormValidatorContext)
}

// Use this when consumer should be wrapped in FormValidatorProvider, otherwise use useFormValidator
export const useSafeFormValidator = () => {
  const context = useContext(FormValidatorContext)
  if (context == null) {
    throw new Error(`useSafeFormValidator must be used within a FormValidatorProvider`)
  }
  return context
}

export const FormValidatorProvider: React.FC = ({ children }) => {
  const [validated, setValidated] = useState(false)
  const pushErrorCallback = useRef<(errors: FormError<{}>) => void>()
  const form = useLapeContext()
  const wrapperRef = useRef<HTMLDivElement>(null)

  const onPushError = useCallback(
    (cb: (errors: FormError<{}>) => void) => {
      pushErrorCallback.current = cb
    },
    [form],
  )

  const scrollToField = (inputNames: string[], error?: AxiosError) => {
    const inputs = inputNames.map(name => {
      const element = (wrapperRef.current || document).querySelector(
        `[data-name~="${name}"]`,
      )
      return {
        element,
        top: element?.getBoundingClientRect().top,
      }
    })

    const topmostElement = inputs.reduce<{
      element: Element | null
      top: number | undefined
    } | null>((topmost, element) => {
      if (element.top != null && (topmost?.top == null || element.top < topmost.top)) {
        return element
      }
      return topmost
    }, null)

    // Noticed an issue of `scrollIntoView` getting cancelled by other events (like modal closing), scheduling fixes this
    setTimeout(() => {
      topmostElement?.element?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    })

    if (inputs.some(input => input.element == null) && error) {
      pushError({ error })
      pushErrorCallback.current?.({ error })
    }
  }

  const validate = useCallback(
    (callback: () => Promise<any>) => () => {
      setValidated(true)

      if (form.valid) {
        callback().catch(error => {
          if (error?.response?.status === 500) {
            Sentry.captureException(error)
          }

          form.apiErrors = arrayErrorsToFormError(error?.response?.data)
          const fieldsWithApiError = omit(form.apiErrors, errorKeysToIgnore)

          if (
            error?.response?.status === 400 &&
            Object.keys(fieldsWithApiError).length > 0
          ) {
            const flatErrors = flatten<Partial<FormError<{}>>, { [key: string]: any }>(
              fieldsWithApiError,
            )
            Object.keys(flatErrors).forEach(key => {
              if (isEmpty(flatErrors[key])) {
                delete flatErrors[key]
              }
            })
            const inputErrors = Object.keys(flatErrors)
            scrollToField(inputErrors, error)
          } else if (error?.response?.status === 413) {
            pushNotification({
              type: NotificationTypes.error,
              value: 'File is too large, please upload a file smaller than 25MB.',
              duration: ERROR_DEFAULT_DURATION,
            })
          } else if (error) {
            pushError({ error })
            pushErrorCallback.current?.({ error })
          }
        })
      } else {
        scrollToField(Object.keys(flatten(form.errors)))
      }
    },
    [form],
  )

  /** When you need to generate input errors from the front-end */
  const forceErrors = useCallback(
    (errors: FormError<{}>) => {
      setValidated(true)
      form.errors = errors
      const flatErrors = flatten<Partial<FormError<{}>>, { [key: string]: any }>(errors)
      scrollToField(Object.keys(flatErrors))
    },
    [form],
  )

  const contextValue = useMemo(
    () => ({
      validated,
      validate,
      forceErrors,
      onPushError,
    }),
    [validated, validate, forceErrors, onPushError],
  )

  return (
    <FormValidatorContext.Provider value={contextValue}>
      <Box display="contents" ref={wrapperRef}>
        {children}
      </Box>
    </FormValidatorContext.Provider>
  )
}
