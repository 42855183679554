import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { UploadSessionV2, UploadSessionV2Props } from './UploadSession'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'
import { BulkDataImportUploadFile } from '@src/features/BulkDataImport/BulkDataImportUploadFile'
import { ImportDataCategory } from '@src/interfaces/bulkDataImport'
import { OnboardingChecklistHeader } from '../OnboardingChecklistHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@src/components/Page/PageBody'
import { TableActionsProps } from '@src/features/GenericEditableTable/GenericEditableTable'

type OnboardingBulkDataUploadProps<T> = {
  importRoute: string
  sessionRoute: string
  anyRoute: string
  apiEndpoint: string
  category: ImportDataCategory
  name: string
  tableActions?: (props: TableActionsProps) => React.ReactNode
} & Pick<UploadSessionV2Props<T>, 'row' | 'tableName' | 'entity'>

export const OnboardingBulkDataUploadV2 = <T,>({
  importRoute,
  sessionRoute,
  anyRoute,
  apiEndpoint,
  category,
  name,
  tableName,
  row,
  entity,
  tableActions,
}: OnboardingBulkDataUploadProps<T>) => {
  return (
    <PageWrapper>
      <OnboardingChecklistHeader
        title="Import employees"
        backUrl={pathToUrl(ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.IMPORT)}
      />
      <Switch>
        <Route exact path={importRoute}>
          {/* TODO: we need v2 of this flow, temporarily reusing old one */}
          <PageBody>
            <BulkDataImportUploadFile
              category={category}
              apiEndpoint={apiEndpoint}
              name={name}
              nextRoute={sessionRoute}
            />
          </PageBody>
        </Route>
        <Route exact path={sessionRoute}>
          <UploadSessionV2
            apiEndpoint={apiEndpoint}
            previousRoute={importRoute}
            tableName={tableName}
            row={row}
            entity={entity}
            tableActions={tableActions}
          />
        </Route>
        <Route exact path={anyRoute}>
          <InternalRedirect to={importRoute} />
        </Route>
      </Switch>
    </PageWrapper>
  )
}
