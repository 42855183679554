import React from 'react'
import { OnboardingIntro } from '@components/OnboardingIntro/OnboardingIntro'
import { OnboardingIntroTips } from '@components/OnboardingIntro/OnboardingIntroTips'
import { FormattedMessage } from 'react-intl'

export const DocumentsIntro = () => {
  return (
    <OnboardingIntro
      videoUrl="/intros/Intro%20-%20Documents.mov"
      videoPosterUrl="/intros/Intro%20-%20Documents.png"
    >
      <OnboardingIntroTips
        title={
          <FormattedMessage
            id="onboardingV2.documents.intro.tips.title"
            defaultMessage="Organise and manage all your documents in secure place"
          />
        }
        text={
          <FormattedMessage
            id="onboardingV2.documents.intro.tips.text"
            defaultMessage="Upload all employee documents, store company policies and generate eSignatures."
          />
        }
        items={[
          {
            title: (
              <FormattedMessage
                id="onboardingV2.documents.intro.tips.upload.title"
                defaultMessage="Upload employee documents"
              />
            ),
            icon: 'Document',
            text: (
              <FormattedMessage
                id="onboardingV2.documents.intro.tips.upload.text"
                defaultMessage="All your employee documents can be stored in one central place"
              />
            ),
          },
          {
            title: (
              <FormattedMessage
                id="onboardingV2.documents.intro.tips.store.title"
                defaultMessage="Store all company policies in one place"
              />
            ),
            icon: 'People',
            text: (
              <FormattedMessage
                id="onboardingV2.documents.intro.tips.store.text"
                defaultMessage="After a policy is created, decide who this policy is assigned to by using filters or groups. For example assign an Annual leave policy to everyone in the UK who is a senior. From there on out, anyone who fits this criteria will be assigned the policy."
              />
            ),
          },
          {
            title: (
              <FormattedMessage
                id="onboardingV2.documents.intro.tips.request.title"
                defaultMessage="Request documents & signatures"
              />
            ),
            icon: 'People',
            text: (
              <FormattedMessage
                id="onboardingV2.documents.intro.tips.request.text"
                // @TODO: change text
                defaultMessage="After a policy is created, decide who this policy is assigned to by using filters or groups. For example assign an Annual leave policy to everyone in the UK who is a senior. From there on out, anyone who fits this criteria will be assigned the policy."
              />
            ),
          },
        ]}
      />
    </OnboardingIntro>
  )
}
