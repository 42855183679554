import React from 'react'
import { get } from 'lodash'

import { CellTypes } from '@src/interfaces/data'
import { ImportEmployeeDataInterface } from '@src/interfaces/importEmployees'
import { selectorKeys } from '../api'
import { EmployeesSimpleInterface } from '@src/interfaces/employees'
import {
  GenericEditableTableColumn,
  GenericEditableTableOnChange,
  MultiSelectInputCell,
  MultiSelectInputCellProps,
  RadioSelectInputCell,
  TextCell,
} from '@src/features/GenericEditableTable/components'

type ImportEmployeeColumn = GenericEditableTableColumn<ImportEmployeeDataInterface>

type OnboardingEmployeeColumn = GenericEditableTableColumn<EmployeesSimpleInterface>

export const importEmployeesV2FirstNameColumn: ImportEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'first_name',
  dataPoint: 'first_name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'First name',
  insert: ({ data }) => <TextCell data={data} onChange={onChange} field="first_name" />,
})

export const importEmployeesV2LastNameColumn: ImportEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'last_name',
  dataPoint: 'last_name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Last name',
  insert: ({ data }) => <TextCell data={data} onChange={onChange} field="last_name" />,
})

export const importEmployeesV2EmailColumn: ImportEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'email',
  dataPoint: 'email',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Work email',
  insert: ({ data }) => <TextCell data={data} onChange={onChange} field="email" />,
})

export const importEmployeesV2TeamColumn: ImportEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'team',
  dataPoint: 'team',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Team',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="team"
      selector={selectorKeys.team}
      useNameField
    />
  ),
})

export const importEmployeesV2RoleColumn: ImportEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'specialisation',
  dataPoint: 'specialisation',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Role',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="specialisation"
      selector={selectorKeys.specialisations}
      useNameField
      fieldName="role"
    />
  ),
})

export const importEmployeesV2SeniorityColumn: ImportEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'seniority',
  dataPoint: 'seniority',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Seniority',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="seniority"
      selector={selectorKeys.seniority}
      useNameField
    />
  ),
})

export const importEmployeesV2AccessGroupColumn: ImportEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'access_level',
  dataPoint: 'access_level',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Access group',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="access_level"
      fieldName="access group"
      selector={selectorKeys.groups}
      useNameField
    />
  ),
})

export const onboardingEmployeesFirstNameColumn: OnboardingEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'first_name',
  dataPoint: 'first_name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'First name',
  insert: ({ data }) => <TextCell data={data} onChange={onChange} field="first_name" />,
})

export const onboardingEmployeesLastNameColumn: OnboardingEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'last_name',
  dataPoint: 'last_name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Last name',
  insert: ({ data }) => <TextCell data={data} onChange={onChange} field="last_name" />,
})

export const onboardingEmployeesEmailColumn: OnboardingEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'email',
  dataPoint: 'email',
  sortKey: 'email',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Work email',
  insert: ({ data }) => <TextCell data={data} onChange={onChange} field="email" />,
})

export const onboardingEmployeesTeamColumn: OnboardingEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'team',
  dataPoint: 'team',
  sortKey: 'team__name',
  filterKey: 'team__id',
  selectorsKey: selectorKeys.team,
  title: 'Team',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="team"
      selector={selectorKeys.team}
    />
  ),
})

export const onboardingEmployeesRoleColumn: OnboardingEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'position',
  dataPoint: 'position',
  sortKey: 'position__name',
  filterKey: 'position__id',
  selectorsKey: selectorKeys.specialisations,
  title: 'Role',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="position"
      selector={selectorKeys.specialisations}
      fieldName="role"
    />
  ),
})

export const onboardingEmployeesSeniorityColumn: OnboardingEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'seniority',
  dataPoint: 'seniority',
  sortKey: 'seniority__name',
  filterKey: 'seniority__id',
  selectorsKey: selectorKeys.seniority,
  title: 'Seniority',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="seniority"
      selector={selectorKeys.seniority}
    />
  ),
})

const AccessGroupMultiSelect = ({
  data,
  onChange,
}: Pick<MultiSelectInputCellProps<EmployeesSimpleInterface>, 'data' | 'onChange'>) => {
  const accessGroups = get(data.data, 'access_groups')

  const label = accessGroups?.map(group => group.group_name).join(', ')

  const value = accessGroups.map(group => ({
    id: group.group_id,
    name: group.group_name,
  }))

  const onValueChange: GenericEditableTableOnChange = (rowId, val, field) => {
    if (Array.isArray(val)) {
      onChange(
        rowId,
        val.map(group => ({ group_id: group.id, group_name: group.name })),
        field,
      )
    }
  }

  return (
    <MultiSelectInputCell
      data={data}
      value={value}
      onChange={onValueChange}
      field="access_groups"
      fieldName="access groups"
      selector={selectorKeys.groups}
      label={label}
    />
  )
}

export const onboardingEmployeesAccessGroupsColumn: OnboardingEmployeeColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'access_groups',
    dataPoint: 'access_groups',
    sortKey: 'access_groups',
    filterKey: 'access_groups',
    selectorsKey: selectorKeys.groups,
    title: 'Access groups',
    insert: ({ data }) => <AccessGroupMultiSelect data={data} onChange={onChange} />,
  })
