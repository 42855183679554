import { ROUTES } from '@src/constants/routes'
import EmployeeTable from '@src/pages/People/PeopleSubTabs/Employees/EmployeeTable'
import OnboardingEmployeesTable from '@src/pages/People/PeopleSubTabs/OnboardingEmployees/OnboardingEmployeesTable'
import NotificationTable from '@src/pages/People/PeopleSubTabs/Notifications/NotificationsTable'
import { GlobalSettings, PermissionTypes } from '@src/store/auth/types'
import ResignationsTable from '@src/pages/People/PeopleSubTabs/Resignations/ResignationsTable'
import Offboarding from '@src/pages/People/PeopleSubTabs/Offboarding/OffboardingTable'
import AttendancePolicies from '@src/pages/People/PeopleSubTabs/Attendance/Policies'
import AttendanceScheduler from '@src/pages/People/PeopleSubTabs/Attendance/Scheduler'
import AttendanceShiftBreakdown from '@src/pages/People/PeopleSubTabs/Attendance/ShiftBreakdown'
import AttendanceShiftSummary from '@src/pages/People/PeopleSubTabs/Attendance/ShiftSummary'
import TimeOffPolicies from '@src/pages/People/PeopleSubTabs/TimeOffPage/Policies'
import TimeOffPublicHolidays from '@src/pages/People/PeopleSubTabs/TimeOffPage/PublicHolidays'
import TimeOffRegimes from '@src/pages/People/PeopleSubTabs/TimeOffPage/Regimes'
import TimeOffRequests from '@src/pages/People/PeopleSubTabs/TimeOffPage/Requests'
import { TimeOffCategories } from '@src/pages/People/PeopleSubTabs/TimeOffPage/Categories'
import { AllDocuments } from '@src/pages/People/PeopleSubTabs/Documents/AllDocuments'
import { ContractsTable } from '@src/pages/People/PeopleSubTabs/Contracts/ContractsTable'
import { HomeSectionId, TabBarWithPinningTabInterface } from '@src/interfaces/tabPinning'
import BackgroundJobs from '@src/pages/People/PeopleSubTabs/BackgroundJobs/BackgroundJobs'
import { TimeManagement } from '@src/pages/People/PeopleSubTabs/TimeManagement/TimeManagement'

export const peopleSubtabs: TabBarWithPinningTabInterface[] = [
  {
    id: HomeSectionId.employees,
    title: 'Employees',
    path: ROUTES.PEOPLE.EMPLOYEES,
    url: ROUTES.PEOPLE.EMPLOYEES,
    permission: PermissionTypes.ViewEmployees,
    component: EmployeeTable,
  },
  {
    id: HomeSectionId.timeManagement,
    title: 'Time Management',
    path: ROUTES.PEOPLE.TIME_MANAGEMENT,
    url: ROUTES.PEOPLE.TIME_MANAGEMENT,
    component: TimeManagement,
  },
  {
    id: HomeSectionId.contracts,
    title: 'Contracts',
    permission: PermissionTypes.ViewContractsTab,
    path: ROUTES.PEOPLE.CONTRACTS,
    url: ROUTES.PEOPLE.CONTRACTS,
    component: ContractsTable,
  },
  {
    id: HomeSectionId.offboarding,
    title: 'Offboarding',
    path: ROUTES.PEOPLE.OFFBOARDING,
    url: ROUTES.PEOPLE.OFFBOARDING,
    permission: PermissionTypes.ViewOffboardingV2,
    component: Offboarding,
  },
  {
    id: HomeSectionId.onboarding,
    title: 'Onboarding',
    path: ROUTES.PEOPLE.ONBOARDING,
    url: ROUTES.PEOPLE.ONBOARDING,
    permission: PermissionTypes.ViewEmployeeHROnboardingProcess,
    component: OnboardingEmployeesTable,
  },
  {
    id: HomeSectionId.resignations,
    title: 'Resignations',
    path: ROUTES.PEOPLE.RESIGNATIONS,
    url: ROUTES.PEOPLE.RESIGNATIONS,
    permission: PermissionTypes.ViewResignation,
    globalSetting: GlobalSettings.ResignationsEnabled,
    component: ResignationsTable,
  },
  {
    id: HomeSectionId.documents,
    title: 'Documents',
    path: ROUTES.PEOPLE.DOCUMENTS,
    url: ROUTES.PEOPLE.DOCUMENTS,
    permission: PermissionTypes.ViewEmployeeDocuments,
    component: AllDocuments,
  },
  {
    id: HomeSectionId.notifications,
    title: 'Notifications',
    permission: PermissionTypes.ViewTemplatedNotifications,
    path: ROUTES.PEOPLE.NOTIFICATIONS,
    url: ROUTES.PEOPLE.NOTIFICATIONS,
    component: NotificationTable,
  },
  {
    id: HomeSectionId.timeOff,
    title: 'Time Off',
    path: ROUTES.PEOPLE.TIME_OFF.ANY,
    url: ROUTES.PEOPLE.TIME_OFF.REQUESTS,
    globalSetting: GlobalSettings.TimeOffEnabled,
    subtabs: [
      {
        id: 'requests',
        title: 'Requests',
        path: ROUTES.PEOPLE.TIME_OFF.REQUESTS,
        to: ROUTES.PEOPLE.TIME_OFF.REQUESTS,
        component: TimeOffRequests,
        permission: PermissionTypes.ViewTimeOffRequestsOfReports,
      },
      {
        id: 'policies',
        title: 'Policies',
        path: ROUTES.PEOPLE.TIME_OFF.POLICIES,
        to: ROUTES.PEOPLE.TIME_OFF.POLICIES,
        component: TimeOffPolicies,
        permission: PermissionTypes.ViewTimeOffPolicies,
      },
      {
        id: 'regimes',
        title: 'Regimes',
        path: ROUTES.PEOPLE.TIME_OFF.REGIMES,
        to: ROUTES.PEOPLE.TIME_OFF.REGIMES,
        component: TimeOffRegimes,
        permission: PermissionTypes.ViewTimeOffRegimes,
      },
      {
        id: 'public-holidays',
        title: 'Public holidays',
        path: ROUTES.PEOPLE.TIME_OFF.PUBLIC_HOLIDAYS,
        to: ROUTES.PEOPLE.TIME_OFF.PUBLIC_HOLIDAYS,
        component: TimeOffPublicHolidays,
        permission: PermissionTypes.ViewPublicHolidays,
      },
      {
        id: 'categories',
        title: 'Categories',
        path: ROUTES.PEOPLE.TIME_OFF.CATEGORIES,
        to: ROUTES.PEOPLE.TIME_OFF.CATEGORIES,
        component: TimeOffCategories,
        permission: PermissionTypes.ViewTimeOffPolicyCategory,
      },
    ],
  },
  {
    id: HomeSectionId.attendance,
    title: 'Attendance',
    path: ROUTES.PEOPLE.ATTENDANCE.ANY,
    url: ROUTES.PEOPLE.ATTENDANCE.SCHEDULER,
    globalSetting: GlobalSettings.AttendanceEnabled,
    subtabs: [
      {
        id: 'schedule',
        title: 'Schedule',
        path: ROUTES.PEOPLE.ATTENDANCE.SCHEDULER,
        to: ROUTES.PEOPLE.ATTENDANCE.SCHEDULER,
        component: AttendanceScheduler,
        permission: PermissionTypes.ViewDirectReportsSchedule,
      },
      {
        id: 'shifts',
        title: 'Shifts',
        path: ROUTES.PEOPLE.ATTENDANCE.SHIFT_SUMMARY,
        to: ROUTES.PEOPLE.ATTENDANCE.SHIFT_SUMMARY,
        component: AttendanceShiftSummary,
        permission: PermissionTypes.ViewShiftsSummary,
      },
      {
        id: 'compensation',
        title: 'Compensation',
        path: ROUTES.PEOPLE.ATTENDANCE.SHIFT_BREAKDOWN,
        to: ROUTES.PEOPLE.ATTENDANCE.SHIFT_BREAKDOWN,
        component: AttendanceShiftBreakdown,
        permission: PermissionTypes.ViewShiftsBreakdown,
      },
      {
        id: 'policies',
        title: 'Policies',
        path: ROUTES.PEOPLE.ATTENDANCE.POLICIES,
        to: ROUTES.PEOPLE.ATTENDANCE.POLICIES,
        component: AttendancePolicies,
        permission: PermissionTypes.ViewSchedulingPolicy,
      },
    ],
  },
  {
    id: HomeSectionId.backgroundJobs,
    title: 'Background Jobs',
    path: ROUTES.PEOPLE.BACKGROUND_JOBS,
    url: ROUTES.PEOPLE.BACKGROUND_JOBS,
    component: BackgroundJobs,
    permission: PermissionTypes.ViewBackgroundJobs,
  },
]
