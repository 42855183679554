import { AxiosPromise } from 'axios'

import { FetchDataQueryInterface } from '@src/interfaces/data'
import { api, apiWithoutHandling } from '.'
import { uploadFile } from './files'
import { GetRequestInterface } from '@src/interfaces'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { ImportInterface, ImportSessionInterface } from '@src/interfaces/bulkDataImport'
import { useFetch } from '@src/utils/reactQuery'

export const importFile = async (file: File, endpoint: string, category: string) => {
  const uploadResult = await uploadFile(file, category, true, apiWithoutHandling)

  return apiWithoutHandling.post(endpoint, {
    file: { id: uploadResult.data.id },
  })
}

export const getImportFileTemplate = async (endpoint: string) => {
  const res = await api.get<Blob>(`${endpoint}/template`, {
    responseType: 'blob',
  })
  return URL.createObjectURL(res.data)
}

export const getUploadHistory = (
  endpoint: string,
  { sortBy, filters, page }: FetchDataQueryInterface,
): AxiosPromise<GetRequestInterface<ImportSessionInterface>> =>
  api.get(endpoint, { params: filterSortPageIntoQuery(sortBy, filters, page) })

export const useGetImportSessionData = (apiEndpoint: string, id: string) => {
  const data = useFetch<ImportSessionInterface>(
    `${apiEndpoint}/${id}`,
    undefined,
    undefined,
    true,
    {
      refetchInterval: session =>
        session?.state.id === 'pending' ||
        session?.state.id === 'processing_file' ||
        session?.state.id === 'applying'
          ? 1000
          : false,
    },
  )

  return data
}

export const getUploadSessionTable =
  <T>(apiEndpoint: string, id: string | number) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<ImportInterface<T>>> => {
    return api.get(`${apiEndpoint}/${id}/items`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })
  }

export const deleteImportSessionRow =
  (apiEndpoint: string) => (sessionId: number, rowId: number) =>
    apiWithoutHandling.delete(`${apiEndpoint}/${sessionId}/items/${rowId}`)

export const editImportSessionRow = <T>(
  apiEndpoint: string,
  sessionId: number | string,
  rowId: number,
  data: Pick<ImportInterface<T>, 'data'>,
) =>
  apiWithoutHandling.patch<ImportInterface<T>>(
    `${apiEndpoint}/${sessionId}/items/${rowId}`,
    data,
  )

export const applyImportSession = (apiEndpoint: string, sessionId: number | string) =>
  api.post(`${apiEndpoint}/${sessionId}/apply`)

export const useImportSessionBulkItemSelector = (
  apiEndpoint: string,
  sessionId: number | string | null,
) => {
  const result = useFetch<{ ids: number[] }>(
    `${apiEndpoint}/${sessionId}/items/ids`,
    'v1',
    undefined,
    true,
    {
      enabled: !!sessionId,
    },
  )
  return { options: result.data?.ids || [], refetch: result.refetch }
}

export const getImportSessionBulkItems = (
  apiEndpoint: string,
  sessionId: number | string,
) => apiWithoutHandling.get<{ ids: number[] }>(`${apiEndpoint}/${sessionId}/items/ids`)

export const bulkDeleteImportSessionRows = (
  apiEndpoint: string,
  sessionId: number | string,
  item_ids: number[],
) =>
  apiWithoutHandling.post(`${apiEndpoint}/${sessionId}/items/bulkDelete`, {
    item_ids,
  })

export const bulkEditImportSessionRows = (
  apiEndpoint: string,
  sessionId: number | string,
  item_ids: number[],
  edit_fields: Record<string, string | null>,
) =>
  apiWithoutHandling.post(`${apiEndpoint}/${sessionId}/items/bulkEdit`, {
    item_ids,
    edit_fields,
  })

export const createImportFromEntities = (apiEndpoint: string, entity_ids: number[]) =>
  apiWithoutHandling.post<{ bulk_upload_id: number }>(
    `${apiEndpoint}/createFromEntities`,
    {
      entity_ids,
    },
  )
